:root {
  --black-100: #323232;
  --color-text-white: #f5f5f5;
  --color-text-gray: rgba(12, 6, 38, 0.7);
  --color-text-blue: #3b49df;
  --bg-red: #e96a41;
  --white-100: #fff;
  --bg-orange-light: #e7e4de;
  --bg-orange-lightest: #f7f4f0;
  --bg-green: #3a543e;
  --bg-green-light: #a5cdab;
  --bg-gray: #f7f4f0;
  --bg-black-30: rgba(50, 50, 50, 0.3);
  --bg-black-50: rgba(50, 50, 50, 0.5);
  --bg-white: #fff;
  --logo-yellow: #fbc90b;
  --logo-gray: #9e9e9e;
  --button-pressed: #7a9f80;
  --button-hover: #97ba9c;
  --button-default: #a5cdab;
  --button-disabled: rgba(165, 205, 171, 0.3);
  --swiper-pagination-bullet-inactive-color: #A5CDAB;
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 6px;
  --swiper-pagination-color: #3A543E;
  --font-family-pt-sans: 'PT Sans', Arial, sans-serif;
  --font-family-wf: 'WF', Arial, sans-serif;
  --font-family-wf-pt-sans: 'WF', 'PT Sans', Arial, sans-serif;
}
