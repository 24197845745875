.intro {
  font: 20px var(--font-family-wf-pt-sans);
  margin: 0;
  padding: 0;
  background-color: var(--bg-gray);
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.intro__container {
  max-width: 1440px;
  margin: 0 auto;
  width: calc(100% - 64px);
  text-align: left;
}

.intro__title {
  font: 2.3em/1.2em var(--font-family-wf);
  font-weight: 400;
  max-width: 1163px;
  color: var(--black-100);
  padding: 70px 0 0 32px;
  word-wrap: normal;
  margin: 0;
  overflow-wrap: anywhere;
  text-align: left;
}

@media screen and (max-width: 1023px) {
  .intro {
    font-size: 14px;
  }
  .intro__container {
    margin: 0 auto;
    max-width: 736px;
    width: calc(100% - 24px);
  }
  .intro__title {
    max-width: 676px;
    width: 100%;
    padding: 40px 0 0;
    font-size: 2.14em;
    line-height: 1.23em;
  }
}

@media screen and (max-width: 767px) {
  .intro__container {
    margin: 0 auto;
    width: calc(100% - 24px);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .intro__title {
    width: 100%;
    padding: 20px 0 0;
    font-size: 1.86em;
    line-height: 1em;
  }
}
