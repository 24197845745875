.searchResultSection {
  text-align: start;
  width: 100%;
  max-width: 1128px;
  margin-top: 50px;
}

.resultsNumber {
  font-family: "PT Sans";
  font-style: italic;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  padding-bottom: 30px;
}

@media screen and (max-width: 768px) {
  .resultsNumber {
    font-size: 14px;
    line-height: 18px;
    padding-bottom: 15px;
  }
  .searchResultSection {
    padding: 0 14px;
  }
}
