.title {
  text-align: left;
  font: 36px var(--font-family-wf);
  line-height: 45px;
  font-weight: 400;
  color: var(--black-100);
  max-width: 100%;
  padding: 0 0 52px;
  margin: 0 auto;
}

@media screen and (max-width: 1023px) {
  .title {
    font-size: 24px;
    line-height: 30px;
    width: calc(100% - 24px);
    padding-top: 11px;
    padding-bottom: 20px;
    margin: 0;
  }
}
