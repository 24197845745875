.video {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .video {
    align-items: start;
  }
}

.video__container {
  display: flex;
  flex-direction: column;
  max-width: 678px;
  width: 100%;
  max-height: 410px;
}

.video__iframe {
  width: 100%;
  max-height: 360px;
  align-self: center;
}


.video__figCaption {
  text-align: start;
  font: 14px var(--font-family-pt-sans);
  line-height: 18px;
  font-weight: 400;
  max-width: 100%;
  padding: 16px 0;
  margin: 0;
  background-color: white;
  border-bottom: 1px solid var(--bg-orange-light);
}
