.container {
  max-width: 100%;
  margin: 0;
}

.image {
  width: 100%;
  max-height: 520px;
  object-fit: contain;
  padding: 30px;
  box-sizing: border-box;
  background: var(--bg-gray);
}

.description {
  text-align: start;
  font: 14px var(--font-family-pt-sans);
  line-height: 18px;
  font-weight: 400;
  max-width: 678px;
  padding: 16px 0;
  margin: 0 auto;
  background-color: white;
  border-bottom: 1px solid var(--bg-orange-light);
}
