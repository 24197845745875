p {
  margin: 0;
}

.card {
  width: 100%;
  max-width: 328px;
  min-width: 180px;
  height: 526px;
  overflow: hidden;
  color: var(--color-text-white);
  position: relative;
  cursor: pointer;
  border-radius: 7px;
  font-family: var(--font-family-pt-sans);
  font-weight: normal;
  font-style: normal;
  transition: all 0.2s linear;
}

.card:hover {
  opacity: 0.8;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #323232;
  opacity: 0.6;
  z-index: 1;
}

.name {
  font-family: var(--font-family-wf);
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 100%;
  text-align: center;
  color: var(--black-100);
}

.text {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 12px 16px 24px;
  box-sizing: border-box;
  text-align: left;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 12px;
}

.type {
  font-style: italic;
  font-size: 16px;
  line-height: 21px;
  z-index: 2;
  margin-bottom: auto;
}

.title {
  font-family: var(--font-family-wf);
  font-size: 26px;
  line-height: 26px;
}

.subtitle {
  font-size: 20px;
  line-height: 26px;
}

.quote {
  font-size: 14px;
  line-height: 18px;
}

.cardExp {
  width: 100%;
  max-width: 328px;
  min-width: 180px;
  height: 526px;
  padding: 32px 15px 28px;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  border-radius: 7px;
  background-color: var(--bg-orange-light);
  transition: all 0.2s linear;
}

.cardExp:hover {
  opacity: 0.8;
}

.imgExp {
  display: inline-block;
  border-radius: 50%;
  max-width: 204px;
}

.name {
  font-family: var(--font-family-wf);
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 100%;
  text-align: center;
  color: var(--black-100);
}

.quoteExp {
  font-family: var(--font-family-pt-sans);
  font-size: 14px;
  line-height: 18px;
  margin-block-end: auto;
}

.typeExp {
  font-family: var(--font-family-pt-sans);
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
}

@media screen and (max-width: 1023px) {
  .card {
    height: 280px;
  }
  .text {
    padding: 12px 8px;
  }
  .cardExp {
    padding: 12px 8px 10px;
    height: 280px;
  }
  .quoteExp {
    font-size: 12px;
    line-height: 16px;
  }
  .type,
  .typeExp {
    font-size: 12px;
    line-height: 16px;
  }
  .title,
  .name {
    font-size: 18px;
    line-height: 22px;
  }
  .subtitle {
    font-size: 14px;
    line-height: 18px;
  }
  .cardExp {
    gap: 4px;
  }
  .quote {
    display: none;
  }
  .imgExp {
    max-width: 86px;
  }
}

@media screen and (max-width: 767px) {
  .card {
    width: 252px;
    height: 352px;
  }
  .cardExp {
    width: 252px;
    height: 352px;
    gap: 12px;
    padding: 16px 8px;
  }
  .quote {
    display: block;
  }
  .imgExp {
    max-width: 160px;
  }
  .text {
    padding-bottom: 25px;
  }
}
