li {
  list-style: none;
}

.newsItem {
  max-width: 328px;
  min-width: 180px;
  display: grid;
  grid-template-columns: minmax(min-content, 100px) minmax(min-content, 255px);
  row-gap: 20px;
  font: normal 400 14px/18px var(--font-family-pt-sans);
  color: var(--black-100);
  cursor: pointer;
}

.newsItem__image {
  grid-row: 1/2;
  grid-column: 2/3;
  display: block;
  justify-self: end;
  width: 100%;
  max-width: 212px;
  min-width: 102px;
  aspect-ratio: 212/223;
  height: auto;
  object-fit: cover;
}

.newsItem__date {
  display: block;
  text-align: start;
  padding-bottom: 8px;
}

.newsItem__title {
  margin: 0;
  font-size: 14px;
  line-height: 18px;
  text-align: start;
  font-weight: 400;
}

.newsItem__text {
  margin: 0;
  color: rgba(50, 50, 50, 0.6);
  grid-column: 1/3;
  text-align: start;
  font-size: 20px;
  line-height: 26px;
}

@media screen and (max-width: 1023px) {
  .newsItem {
    font-size: 12px;
    line-height: 16px;
    row-gap: 8px;
  }
  .newsItem__title {
    font-size: 12px;
    line-height: 16px;
  }
  .newsItem__date {
    padding-bottom: 4px;
  }
  .newsItem__text {
    font-size: 14px;
    line-height: 18px;
  }
  .newsItem__image {
    aspect-ratio: 17/20;
  }
}

@media screen and (max-width: 767px) {
  .newsItem {
    width: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, min-content);
    aspect-ratio: auto;
    max-width: none;
    min-height: 246px;
    row-gap: 8px;
  }
  .newsItem__titleContainer {
    display: flex;
    grid-row: 3;
    grid-column: 1/3;
    justify-content: space-between;
  }
  .newsItem__title {
    font-style: italic;
  }
  .newsItem__image {
    grid-row: 1;
    grid-column: span 2;
    aspect-ratio: 21/10;
    max-width: none;
  }
  .newsItem__text {
    grid-row: 2;
    grid-column: span 2;
  }
}
