.main {
  box-sizing: border-box;
  padding-top: 110px;
  padding-bottom: 141px;
  width: 100%;

  max-width: 1440px;
  margin: 0 auto;
  display: grid;
  grid-template-areas:
    "title"
    "search"
    "cards";
  justify-items: center;
}

.title {
  box-sizing: border-box;
  max-width: 423px;
  grid-area: title;
  font-family: var(--font-family-wf);
  font-size: 46px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  width: fit-content;
}

.search {
  box-sizing: border-box;
  display: flex;
  grid-area: search;
  padding-top: 52px;
  width: 100%;
  max-width: 1128px;
}

.table {
  box-sizing: border-box;
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: space-evenly;
  gap: 100px;
  flex-wrap: wrap;
  grid-area: cards;
  padding-top: 118px;
}

@media screen and (max-width: 423px) {
  .main {
    padding: 46px 20px 28px 20px;
  }

  .title {
    font-size: 32px;
  }

  .search {
    padding-top: 35px;
  }

  .table {
    padding-top: 66px;
  }
}
