.title {
  font-family: "WF";
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 32px;
  margin: 0;
  padding-bottom: 8px;
}

.paragraph {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 30px;
  white-space: pre-line;
}

.link {
  text-decoration: none;
  color: black;
}

@media screen and (max-width: 768px) {
  .title {
    font-size: 18px;
    line-height: 22px;
    padding-bottom: 0;
  }

  .paragraph {
    font-size: 14px;
    line-height: 18px;
  }
}

@media screen and (min-width: 1280px) {
  .paragraph {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}
