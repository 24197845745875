.container {
  display: flex;
  flex-direction: row;
  gap: 26px;
  box-sizing: border-box;
  width: 100%;
}

.input {
  font-family: var(--font-family-pt-sans);
  flex: 1;
  height: 50px;
  box-sizing: border-box;
  border: 1px solid #000000;
  border-radius: 8px;
  padding: 11px 19px;
  font-size: 20px;
  line-height: 26px;
}

@media screen and (max-width: 767px) {
  .container {
    gap: 14px;
  }
}

@media screen and (max-width: 375px) {
  .input {
    width: 100%;
    box-sizing: border-box;
    font-family: var(--font-family-pt-sans);
  }
}
