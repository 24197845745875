@font-face {
  font-family: 'WF';
  font-style: normal;
  font-weight: 400;
  src:  url("./projito/projito_fonts.woff2") format("woff2"),
        url("./projito/projito_fonts.woff") format("woff");
}

@font-face {
  font-family: 'PT Sans';
  font-style: italic;
  src:  url("./projito/PTSans-Italic.woff2") format("woff2"),
        url("./projito/PTSans-Italic.woff") format("woff");
}

@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  src:  url("./projito/PTSans-Regular.woff2") format("woff2"),
        url("./projito/PTSans-Regular.woff") format("woff");
}
