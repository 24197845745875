.heading {
  font: normal 20px/1.3 var(--font-family-pt-sans);
  margin: 36px 0 16px;
  padding: 0;
}

.tabs {
  display: flex;
  list-style: none;
  margin: 0 auto 30px;
  padding: 0;
  min-height: 50px;
  align-items: center;
}

@media screen and (max-width: 1023px) {
  .heading {
    font-size: 14px;
    line-height: 1.28;
    margin: 15px 0 14px;
  }
  .tabs {
    margin-bottom: 23px;
    min-height: unset;
  }
}
@media screen and (max-width: 480px) {
  .tabs {
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 52px;
    margin: 17px auto 15px;
    align-content: space-between;
    max-width: 252px;
  }
  .heading {
    display: none;
  }
}

/*
@media screen and (max-width: 425px) {
  .tabs{
    margin: 17px 82px 15px;
  }
  }
  */
