.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  gap: 36px;
  flex-grow: 1;
}

.text404 {
  font-size: 130px;
}

.link {
  color: white;
  text-decoration: none;
}

.img {
  max-width: 1200px;
  width: 100%;
}
