.list {
  padding-left: 36px;
  max-width: 100%;
}

.list_item {
  list-style: outside;
  text-align: start;
  font: 20px var(--font-family-pt-sans);
  line-height: 22px;
  font-weight: 400;
  width: 100%;
  max-width: 96%;
  box-sizing: border-box;
  margin: 0 auto;
  padding-bottom: 15px;
}

@media screen and (max-width: 1023px) {
  .list {
    padding-left: 0;

  }

  .list_item {
    font-size: 14px;
    line-height: 22px;
  }
}
