.container {
  display: flex;
  justify-content: space-between;
  padding-top: 25px;
  padding-bottom: 87px;
}

.link {
  display: flex;
  align-items: center;
  gap: 15px;
  text-decoration: none;
}

.linkText {
  color: var(--black-100);
  max-width: 271px;
  text-decoration: underline;
  text-underline-offset: 4px;
}

.linkTextLeft {
  text-align: left;
}

.linkTextRight {
  text-align: right;
}

@media screen and (max-width: 1023px) {
  .container {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  .link:last-child {
    align-self: flex-end;
  }
}

