.tab {
  padding: 16px 10px;
  margin-right: 32px;
}

.tab:last-child {
  margin-right: 0;
}

.button {
  font: normal 14px var(--font-family-pt-sans);
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.line {
  text-decoration-line: underline;
  text-underline-offset: 16px;
}
@media screen and (max-width: 1023px) {
  .tab {
    padding: 0;
    margin-right: 17px;
  }
  .line {
    text-underline-offset: 5px;
  }
  .button {
    font-size: 12px;
    line-height: 1.33;
  }
}
