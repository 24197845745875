.main {
  margin: 0 auto;
  max-width: 1440px;
  padding: 0 20px;
}

.pagesNav {
  font: 14px var(--font-family-pt-sans);
  color: var(--black-100);
  padding: 20px 0 0 32px;
  margin: 0;
  text-align: start;
}

.pagesNavLink {
  font-family: var(--font-family-pt-sans);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: var(--black-100);
  text-decoration: none;
  cursor: pointer;
}

.pagesNavLink:hover {
  border-bottom: var(--black-100) solid 1px;
}

.tag {
  font-family: var(--font-family-pt-sans);
  font-size: 14px;
  line-height: 18px;
  margin: 0 auto 0;
  text-align: center;
  font-weight: 400;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  gap: 32px;
  justify-content: center;
}

.tagPart {
  margin: 0;
  padding: 0;
  position: relative;
}

.tagPart:not(:last-of-type)::after {
  content: '';
  position: absolute;
  top: 7px;
  right: -18px;
  width: 4px;
  height: 4px;
  background-color: var(--black-100);
  border-radius: 10px;
}

.button {
  max-width: 329px;
  width: 100%;
  height: 50px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  background: var(--button-default);
  text-align: center;
}

.button:disabled {
  cursor: unset;
}

.buttonContent {
  font: normal 26px var(--font-family-wf);
  color: var(--black-100);
  position: fixed;
  margin: 0;
  padding: 0;
  top: 435px;
  left: -25px;
  background-color: transparent;
  border: none;
  transform: rotate(-90deg);
  cursor: pointer;
  border-bottom: 1px solid white;
  display: block;
  z-index: 6;
}

.buttonContent:hover {
  border-bottom: 1px solid var(--black-100);
}

@media screen and (max-width: 1023px) {
  .main {
    padding-left: 16px;
    padding-right: 16px;
  }
  .tag {
    font-size: 12px;
    line-height: 16px;
    gap: 24px;
    margin-top: 24px;
  }
  .tagPart:not(:last-of-type)::after {
    right: -14px;
  }
  .button {
    max-width: 237px;
    height: 48px;
  }
  .buttonContent {
    font-size: 18px;
    line-height: 22px;
    top: 263px;
  }
}

@media screen and (max-width: 767px) {
  .main {
    padding: 0;
  }
  .pagesNav {
    padding: 20px 0 0 10px;
    max-width: 300px;
    width: 100%;
  }
  .buttonContent {
    display: none;
  }
}
