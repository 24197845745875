.container {
  display: flex;
  max-height: 575px;
  gap: 31px;
  width: 100%;
  max-width: 510px;
}

.circle {
  width: 85px;
  height: 85px;
  background: #d9d9d9;
  border-radius: 50%;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.wrapper {
  flex: 1;
}

.text {
  font: normal 20px/1.2 var(--font-family-pt-sans);
  text-align: start;
  cursor: pointer;
  transition: 0.5s;
  margin-top: 23px;
}

.title {
  font: 26px/1.2 var(--font-family-wf-pt-sans);
  text-align: start;
  margin-bottom: 24px;
  cursor: pointer;
  transition: 0.5s;
}

@media screen and (max-width: 500px) {
  .circle {
    display: none;
  }
  .title {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 15px;
  }
  .text {
    font-size: 14px;
    line-height: 18px;
    margin-top: 15px;
  }
  .container {
    max-width: 327px;
    gap: 10px;
  }
  .text + .text {
    margin-top: 15px;
  }
}

.text:hover {
  opacity: 0.6;
}

.title:hover {
  opacity: 0.6;
}
