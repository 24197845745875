.main {
  margin: 0 auto;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
}

.container {
  max-width: 1176px;
  margin: 0 auto 32px;
  display: grid;
  grid-template-columns: repeat(3, 328px);
  gap: 66px 80px;
  padding: 0 16px;
  align-items: baseline;
}

.breadcrumbs {
  font-family: var(--font-family-pt-sans);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: var(--black-100);
  text-align: start;
  margin: 20px auto 39px 20px;
}

.breadcrumbsLink {
  text-decoration: none;
  color: var(--black-100);
}

.breadcrumbsLink:hover {
  border-bottom: var(--black-100) solid 1px;
}

@media screen and (max-width: 1200px) {
  .container {
    grid-template-columns: repeat(3, 29%);
    gap: 66px 4vw;
  }
}

@media screen and (max-width: 1023px) {
  .breadcrumbs {
    margin: 20px auto 24px 15px;
  }
  .container {
    max-width: 700px;
    grid-template-columns: repeat(3, 180px);
    gap: 36px;
  }
}

@media screen and (max-width: 767px) {
  .breadcrumbs {
    display: none;
  }
  .container {
    display: flex;
    flex-direction: column;
    padding: 0 12px;
  }
}

.title {
  font-family: var(--font-family-wf);
  font-style: normal;
  font-weight: 400;
  font-size: 46px;
  line-height: 120%;
  color: var(--black-100);
  margin: 0 0 36px 0;
}

@media screen and (max-width: 1023px) {
  .title {
    font-size: 30px;
    margin: 0 0 20px 0;
  }
}

@media screen and (max-width: 767px) {
  .title {
    font-size: 32px;
    margin: 20px 0 16px;
  }
}

.button {
  margin: 0 auto 52px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
