.title {
  text-align: left;
  font: 26px var(--font-family-wf);
  line-height: 26px;
  font-weight: 400;
  color: var(--black-100);
  max-width: 100%;
  padding: 25px 0 15px;
  margin: 0 auto;
}

.text {
  text-align: start;
  font: 20px var(--font-family-pt-sans);
  line-height: 26px;
  font-weight: 400;
  width: 100%;
  padding-bottom: 15px;
  max-width: 1040px;
  margin: 0 auto;
}

.text:last-of-type {
  padding-bottom: 40px;
}

.container__warning {
  border: 1px solid var(--black-100);
  border-radius: 8px;
  padding: 12px 16px;
  margin-bottom: 15px;
}

.container__warning > .text {
  padding-bottom: 0;
}

.text__warning {
  color: var(--bg-red);
  font-weight: 700;
}

.link__warning {
  color: var(--bg-red);
}

@media screen and (max-width: 1023px) {
  .title {
    font-size: 18px;
    line-height: 23px;
    width: calc(100% - 24px);
    padding-top: 5px;
    padding-bottom: 15px;
    margin: 0;
  }

  .text {
    font-size: 14px;
    line-height: 22px;
  }

  .text:last-of-type {
    padding-bottom: 15px;
  }
}

