.main {
  display: flex;
  margin: 0 auto;
  max-width: 1400px;
  width: 100%;
  box-sizing: border-box;
  gap: 40px;
  padding: 20px;
}

.content {
  width: 100%;
  max-width: 1040px;
  min-width: 280px;
}


@media screen and (max-width: 1023px) {
  .main {
    gap: 0;
  }
}
