.table {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: rgba(231, 228, 222, 1);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
}

.table__container {
  padding-top: 138px;
  width: 100%;
  max-width: 926px;
}

.table__title {
  text-align: center;
  margin: 0 auto;
  padding: 36px 0 46px;
  font-family: var(--font-family-wf);
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 100%;
  color: var(--black-100);
  width: 100%;
  border-bottom: 1px solid rgba(50, 50, 50, 0.5);
}

.table__title:hover {
  cursor: pointer;
}

.table__close {
  border: 0;
  background-image: url(../../../images/popup-contest__close-button.svg);
  background-color: rgba(0, 0, 0, 0);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: absolute;
  width: 22px;
  height: 22px;
  top: 37px;
  right: 37px;
  cursor: pointer;
}
.table__list {
  background-color: var(--bg-orange-lightest);
  font-family: var(--font-family-pt-sans);
  font-size: 18px;
  line-height: 30px;
  font-weight: 400;
  list-style-type: disc;
  padding-left: 20px;
}

.table__item {
  list-style: disc;
}

@media screen and (max-width: 1023px) {
  .table__container {
    padding-top: 114px;
    max-width: 612px;
  }
  .table__title {
    font-size: 24px;
    line-height: 30px;
    padding: 32px 0;
  }
}

@media screen and (max-width: 767px) {
  .table__container {
    padding: 49px 12px 0;
    box-sizing: border-box;
  }
  .table__title {
    font-size: 26px;
    line-height: 26px;
  }
  .table__close {
    top: 17px;
    right: 17px;
  }
}
