.menu__wrapper {
  display: flex;
  gap: 15px;
  flex-direction: column;
  font-family: var(--font-family-pt-sans);
  font-style: normal;
  font-size: 20px;
  line-height: 26px;
  padding-left: 27px;
  max-width: 320px;
  text-align: left;
  padding-top: 25px;
}

/*@media screen and (max-width: 1023px) {
  .menu__wrapper {
    font-size: 14px;
    line-height: 18px;
  }
}*/

.menu__title {
  margin: 0;
  padding-left: 27px;
  font-size: inherit;
  font-weight: 700;
}

