.accordion__row {
  width: 100%;
}

.accordion__title {
  display: grid;
  grid-template-columns: 15px 1fr;
  gap: 12px;
  cursor: pointer;
  font-weight: 700;
  text-align: left;
}

.accordion__title:hover {
  opacity: 0.8;
}

.accordion__title_active {
  color: var(--bg-red);
}

.accordion__content {
  padding: 15px 0 0 61px;
  max-height: 999px;
  transition: max-height 0.5s ease-in-out;
  overflow: hidden;
}

.accordion__content[aria-expanded="false"] {
  max-height: 0px;
  transition: max-height 0.2s cubic-bezier(0, 1, 0, 1);
}

.accordion__arrow_opened {
  transform: rotate(90deg);
}

.accordion__arrow {
  background-image: url("../../images/accordion-arrow.svg");
  background-repeat: no-repeat;
  background-position: center;
  transition-duration: 0.1s;
  transition-property: transform;
}

.accordion__list {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
}

.accordion__link {
  text-decoration: none;
  color: inherit;
  font-weight: 400;
}

.accordion__link:hover {
  opacity: 0.8;
}
