.intro {
  font: 20px var(--font-family-wf-pt-sans);
  margin: 0;
  padding: 0;
  background-color: var(--bg-gray);
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.intro__cardContainer {
  max-width: 1176px;
  box-sizing: border-box;
  margin: 111px auto 164px;
  padding: 0 16px;
  text-align: left;
}

.intro__cards {
  box-sizing: border-box;
  display: grid;
  margin: 0;
  padding: 0;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
}

@media screen and (max-width: 1023px) {
  .intro {
    font-size: 14px;
  }
  .intro__cardContainer {
    margin-top: 28px;
    margin-bottom: 36px;
  }
  .intro__cards {
    gap: 24px 12px;
    grid-template-columns: repeat(2, 362px);
  }
}

@media screen and (max-width: 767px) {
  .intro__cardContainer {
    margin-top: 93px;
    margin-bottom: 96px;
  }
  .intro__cards {
    grid-template-columns: minmax(30%, 334px);
    gap: 11px;
  }
}
