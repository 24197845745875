.banner {
  margin-bottom: 76px;
  display: flex;
  justify-content: center;
}

.banner__container {
  display: grid;
  grid-template-columns: 1fr 2.06fr;
  grid-template-rows: auto 1fr auto;
  grid-column-gap: 136px;
  width: 100%;
  max-width: calc(1440px - 32px * 2);
  padding: 56px 116px 64px;
  box-sizing: border-box;
  background-color: var(--bg-gray);
  color: var(--black-100);
}
.banner__image {
  grid-column: 1;
  grid-row: 1/4;
  width: 329px;
  height: 440px;
  object-fit: cover;
}
.banner__title {
  font-family: var(--font-family-wf);
  font-weight: 400;
  font-size: 36px;
  line-height: 1;
  text-align: start;
  margin: 0;
}
.banner__text {
  font-family: var(--font-family-pt-sans);
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.3;
  text-align: start;
  color: rgba(50, 50, 50, 0.7);
  margin: 29px 0 0;
}
.banner__buttonsWrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin-top: auto;
  max-width: 678px;
}

@media screen and (max-width: 1400px) {
  .banner__container {
    grid-template-columns: 174px 1fr;
    grid-column-gap: 75px;
    padding: 20px 17px;
  }
  .banner__image {
    width: 174px;
    height: 259px;
  }
  .banner__title {
    font-size: 24px;
    line-height: 1.25;
  }
  .banner__text {
    font-size: 14px;
    line-height: 1.28;
    margin: 13px 0 0;
  }
  .banner {
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 767px) {
  .banner {
    margin-bottom: 27px;
  }
  .banner__container {
    grid-template-columns: minmax(30%, 336px);
    grid-row-gap: 16px;
    justify-content: center;
    justify-items: center;
    padding: 20px 12px;
    min-height: 640px;
  }
  .banner__image {
    grid-column: 1;
    grid-row: 2;
    width: 164px;
    height: 258px;
  }
  .banner__buttonsWrapper {
    min-height: 116px;
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 480px) {
  .banner__container {
    grid-template-rows: repeat(4, min-content);
  }
  .banner__title {
    max-width: 230px;
    margin: 0 auto;
    font-size: 26px;
    line-height: 1;
    text-align: center;
  }
  .banner__text {
    margin: 0;
  }
}
