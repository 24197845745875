.wrapper {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  gap: 12px;
  min-width: 320px;
  max-width: 100%;
}

.audioPlayerSignature {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323232;
}

.audioPlayer {
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  max-height: 144px;
  max-width: 690px;
  padding: 20px 20px 20px 20px;
  background: #e7e4de;
  border-radius: 35px;
  margin: 0 auto;
}

.waveHandler {
  align-self: center;
  max-height: 144px;
  width: 100%;
}

.audioPlayerBaseButton {
  border: none;
  cursor: pointer;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  transition: opacity 0.3s ease-out;
}

.audioPlayerBaseButton:hover {
  opacity: 0.7;
}

.clickableBaseButton {
  transition: transform 0.1s ease-in-out;
}

.clickableBaseButton:active {
  transform: scale(0.8);
}

.audioPlayerPlay {
  composes: audioPlayerBaseButton;
  align-self: center;
  width: 54px;
  height: 54px;

  background-image: url('./../../../public/images/player/Play.svg');
  text-align: center;
  transition: background ease-in-out 0.3s;
}

.audioPlayerPause {
  transition: background ease-in-out 0.3s;
  width: 38px;
  height: 38px;
  background-image: url('./../../../public/images/player/stop.svg');
}

.waveLoadMessage {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;

  /* Центрирование текста */
  display: block;
  width: 100%;
  text-align: center;
}

.audioPlayerBack {
  composes: audioPlayerBaseButton;
  composes: clickableBaseButton;
  align-self: center;
  height: 24px;
  width: 24px;
  padding: 15px;
  background-size: contain;
  background-image: url('./../../../public/images/player/Reverse_anticlockwise.svg');
}

.audioPlayerForward {
  composes: audioPlayerBaseButton;
  composes: clickableBaseButton;
  align-self: center;
  height: 24px;
  width: 24px;
  padding: 15px;
  background-size: contain;
  background-image: url('./../../../public/images/player/Reverse_clockwise.svg');
}

.audioPlayerTime {
  align-self: center;
  padding: 5px 10px 5px 10px;
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;

  background-color: transparent;
}

.audioPlayerSound {
  composes: audioPlayerBaseButton;
  composes: clickableBaseButton;
  align-self: center;
  height: 24px;
  width: 24px;
  padding: 15px;
  background-size: contain;
  background-image: url('./../../../public/./images/player/Mute.svg');
}

.audioPlayerSoundMuted {
  composes: audioPlayerBaseButton;
  composes: clickableBaseButton;
  align-self: center;
  height: 24px;
  width: 24px;
  padding: 15px;
  background-size: contain;
  background-image: url('./../../../public/./images/player/Muted.svg');
}

.blockedButton {
  opacity: 0.7;
  pointer-events: none;
}

@media screen and (prefers-color-scheme: dark) {
  .audioPlayer {
    background: #999;
  }
  .audioPlayerSound {
    background-image: url('./../../../public/images/player/Mute_White.svg');
  }
  .audioPlayerSoundMuted {
    background-image: url('./../../../public/images/player/Muted_White.svg');
  }
  .audioPlayerForward {
    background-image: url('./../../../public/images/player/Reverse_clockwise_White.svg');
  }
  .audioPlayerBack {
    background-image: url('./../../../public/images/player/Reverse_anticlockwise_White.svg');
  }
  .audioPlayerPlay {
    background-image: url('./../../../public/images/player/Play_White.svg');
    background-size: contain;
    width: 38px;
    height: 28px;
  }
  .audioPlayerPause {
    background-image: url('./../../../public/images/player/stop_White.svg');
    width: 38px;
    height: 28px;
  }
}

@media screen and (max-width: 768px) {
  .audioPlayerSignature {
    font-size: 12px;
    line-height: 16px;
  }
  .audioPlayer {
    padding: 10px 20px 10px 20px;
  }
  .wrapper {
    gap: 8px;
  }
}

@media screen and (max-width: 540px) {
  .audioPlayer {
    padding: 10px 20px 10px 4px;
  }
  .wrapper {
    gap: 8px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .audioPlayerPlay {
    margin-left: 15px;
  }
}
