.article {
  margin: 0 auto;
}

.article h1 {
  text-align: center;
  font: 46px var(--font-family-wf);
  line-height: 55px;
  font-weight: 400;
  color: var(--black-100);
  max-width: 678px;
  padding: 38px 0 8px;
  margin: 0 auto;
  border-bottom: 1px solid var(--bg-orange-light);
}

.article h2 {
  text-align: center;
  font: 26px var(--font-family-wf);
  line-height: 26px;
  font-weight: 400;
  max-width: 650px;
  padding: 0;
  margin: 32px auto 28px;
}

.article h2:first-of-type {
  margin-top: 38px;
}

.article p {
  text-align: start;
  font: 18px var(--font-family-pt-sans);
  line-height: 30px;
  font-weight: 400;
  width: 100%;
  max-width: 676px;
  margin: 28px auto;
}

.article p:last-of-type {
  margin-bottom: 52px;
}

.article a {
  color: var(--black-100);
}

.article blockquote {
  position: relative;
  text-align: start;
  font: 26px var(--font-family-wf);
  line-height: 26px;
  font-weight: 400;
  max-width: 676px;
  box-sizing: border-box;
  padding: 0 0 0 12px;
  margin: 0 auto;
  border-left: 2px var(--bg-orange-light) solid;
}

.article ul {
  padding-left: 40px;
  background-color: var(--bg-orange-lightest);
}

.article li {
  list-style: outside;
  text-align: start;
  font: 18px var(--font-family-pt-sans);
  line-height: 30px;
  font-weight: 400;
  width: 100%;
  max-width: 676px;
  box-sizing: border-box;
  padding-left: 10px;
  margin: 0 auto;
}

.article figure {
  width: 910px;
  margin: 0 auto;
  font-size: 0;
}

.article figure > img {
  width: 100%;
  max-height: 520px;
  object-fit: contain;
  padding: 30px;
  box-sizing: border-box;
  background: var(--bg-gray);
}

.article figcaption {
  text-align: start;
  font: 14px var(--font-family-pt-sans);
  line-height: 18px;
  font-weight: 400;
  max-width: 678px;
  padding: 16px 0;
  margin: 0 auto;
  background-color: white;
  border-bottom: 1px solid var(--bg-orange-light);
}

.gallery > .content {
  width: 100%;
  min-height: 571px;
  position: relative;
}

.gallery > .content > figure {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.gallery > .content img {
  height: 100%;
}

.galleryContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
}

.paginationContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  min-width: 108px;
  gap: 20px;
}

.paginationArrow {
  width: 16px;
  margin-top: 2px;
  cursor: pointer;
  transition: all 0.2s linear;
}

.paginationArrow:hover {
  opacity: 0.8;
}

.paginationStep {
  font-size: 14px;
  line-height: 18px;
}

.article span {
  padding: 0 4px;
  background-color: var(--bg-gray);
  border-radius: 4px;
  max-width: 676px;
  margin: 0 auto 0;
}

.article figure span {
  background-color: var(--bg-orange-light);
}

.article iframe {
  width: 100%;
  max-width: 678px;
  min-height: 360px;
}

@media screen and (max-width: 1023px) {
  .article h1 {
    font-size: 30px;
    line-height: 37px;
    max-width: 612px;
    padding-top: 16px;
  }

  .article h2 {
    font-size: 18px;
    line-height: 22px;
    max-width: 612px;
    margin-top: 20px;
    margin-bottom: 16px;
  }

  .article h2:first-of-type {
    margin-top: 16px;
  }

  .article p {
    font-size: 14px;
    line-height: 22px;
    max-width: 612px;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .article blockquote {
    font-size: 24px;
    line-height: 30px;
    max-width: 612px;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .article ul {
    padding-left: 30px;
  }

  .article li {
    font-size: 14px;
    line-height: 22px;
    max-width: 612px;
    padding-left: 0;
  }

  .article figure {
    width: 100%;
    margin-top: 16px;
  }

  .article figure > img {
    max-width: 736px;
    height: 388px;
    padding: 24px 62px;
  }

  .article figcaption {
    max-width: 612px;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .gallery > .content {
    min-height: 428px;
  }

  .gallery > .content img {
    height: 388px;
  }

  .gallery > .content .paginationArrow {
    height: 14px;
  }

  .gallery > .content > figure {
    margin-top: 0;
  }

  .article iframe {
    min-height: 320px;
  }
}

@media screen and (max-width: 767px) {
  .article h1 {
    font-size: 32px;
    line-height: 38px;
    width: calc(100% - 24px);
    padding-top: 20px;
    padding-bottom: 4px;
    margin-bottom: 24px;
  }

  .article h2 {
    width: calc(100% - 24px);
  }

  .article p {
    line-height: 20px;
    width: calc(100% - 24px);
  }

  .article p:last-of-type {
    margin-bottom: 28px;
  }

  .article blockquote {
    font-size: 18px;
    line-height: 22px;
    width: calc(100% - 24px);
  }

  .article ul {
    padding-left: 16px;
  }

  .article li {
    line-height: 20px;
    width: calc(100% - 24px);
  }

  .article figure > img {
    height: 364px;
    padding: 12px;
  }

  .article figcaption {
    width: calc(100% - 24px);
    padding-top: 8px;
    padding-bottom: 8px;
    margin-bottom: 16px;
  }

  .gallery > .content {
    min-height: 398px;
  }

  .gallery > .content img {
    height: 364px;
  }

  .article iframe {
    max-width: 613px;
  }
}

@media screen and (max-width: 425px) {
  .article iframe {
    max-width: 338px;
    min-height: 168px;
  }
}

/*  AUDIOPLAYER STYLES */

.article button {
  outline: none;
  border: none;
  background-color: transparent;
}

.article audio {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--bg-orange-light);
  max-width: 678px;
  width: 100%;
  height: 112px;
  margin: 32px auto 0;
  border-radius: 35px;
}

.player {
  display: flex;
  flex-direction: column;
}

.player__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--bg-orange-light);
  max-width: 678px;
  width: 100%;
  height: 112px;
  margin: 0 auto;
  border-radius: 35px;
}

.player__button-play {
  border: none;
  background-color: transparent;
  background-image: url(../../../public/images/player/Play.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 24px;
  height: 28px;
  margin: 40px 24px 40px 40px;
  cursor: pointer;
}

.player__button-play:hover {
  opacity: 50%;
}

.player__button-stop {
  border: none;
  background-color: transparent;
  background-image: url(../../../public/images/player/stop.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 24px;
  height: 28px;
  margin: 40px 24px 40px 40px;
  cursor: pointer;
}

.player__button-stop:hover {
  opacity: 50%;
}

.player__button-reverse-anticlockwise {
  border: none;
  background-color: transparent;
  background-image: url(../../../public/images/player/Reverse_anticlockwise.svg);
  background-repeat: no-repeat;
  height: 28px;
  width: 23px;
  margin: 0 8px 0 0;
  cursor: pointer;
}

.player__button-reverse-anticlockwise:hover {
  opacity: 0.5;
}

.player__button-reverse-clockwise {
  border: none;
  background-color: transparent;
  background-image: url(../../../public/images/player/Reverse_clockwise.svg);
  background-repeat: no-repeat;
  height: 28px;
  width: 23px;
  cursor: pointer;
}

.player__button-reverse-clockwise:hover {
  opacity: 0.5;
}

.player__soundtrack {
  width: 340px;
  margin: 0 20px 0 24px;
}

.player__time {
  font: 0.9em/1.67em var(--font-family-pt-sans);
  margin: 0 0 0 33px;
}

.player__button-mute {
  border: none;
  background-color: transparent;
  background-image: url(../../../public/images/player/Mute.svg);
  background-repeat: no-repeat;
  background-position: center;
  height: 27px;
  width: 25px;
  margin: 40px 40px 40px 24px;
  cursor: pointer;
}

.player__button-mute:hover {
  opacity: 0.5;
}

.player__button-unmute {
  border: none;
  background-color: transparent;
  background-image: url(../../../public/images/player/Mute.svg);
  background-repeat: no-repeat;
  background-position: center;
  height: 27px;
  width: 25px;
  margin: 40px 40px 40px 24px;
  cursor: pointer;
  opacity: 0.5;
}

.player__button-unmute:hover {
  opacity: 1;
}

@media screen and (max-width: 1023px) {
  .player__container {
    width: 100%;
    height: 84px;
  }
  .player__button-play {
    margin: 32px 26px 32px 36px;
  }
  .player__button-mute {
    margin: 30px 30px 30px 24px;
  }
}

@media screen and (max-width: 767px) {
  .player__container {
    max-width: 350px;
    width: 100%;
    height: 55px;
  }
  .player__button-play {
    background-image: url(../../../public/images/player/Play_320px.svg);
    width: 16px;
    height: 16px;
    margin: 14px 8px 14px 10px;
  }
  .player__button-stop {
    background-image: url(../../../public/images/player/stop_320px.svg);
    width: 16px;
    height: 16px;
    margin: 14px 8px 14px 10px;
  }
  .player__button-reverse-anticlockwise {
    background-image: url(../../../public/images/player/Reverse_anticlockwise_320px.svg);
    width: 16px;
    height: 16px;
    margin: 0 8px 0 0;
  }
  .player__button-reverse-clockwise {
    background-image: url(../../../public/images/player/Reverse_clockwise_320px.svg);
    width: 16px;
    height: 16px;
  }
  .player__soundtrack {
    width: 175px;
    height: 25px;
    margin: 0 0 0 8px;
  }
  .player__time {
    margin: 0 0 0 16px;
  }
  .player__button-mute {
    background-image: url(../../../public/images/player/Mute_320px.svg);
    margin: 14px 10px 14px 0;
  }
  .player__button-unmute {
    background-image: url(../../../public/images/player/Mute_320px.svg);
    margin: 14px 10px 14px 0;
    opacity: 0.5;
  }
}

@media screen and (max-width: 425px) {
  .player__soundtrack {
    max-width: 145px;
  }
}
