.intro__card {
  border: 1px solid rgba(50, 50, 50, 0.3);
  list-style: none;
  display: flex;
  flex-direction: column;
  min-height: 276px;
  box-sizing: border-box;
}

.intro__cardTitle {
  font: 1.8em/1em var(--font-family-wf);
  margin: 0 auto 0;
  max-width: calc(100% - 60px);
  width: 100%;
  padding: 32px 0 0 0;
  color: var(--black-100);
  font-weight: 400;
  overflow-wrap: anywhere;
}

.intro__cardSubtitle {
  font: 1em/1.3em var(--font-family-pt-sans);
  margin: 11px auto 36px;
  max-width: calc(100% - 60px);
  width: 100%;
  color: var(--black-100);
  min-height: 80px;
}

.intro__linkContainer {
  max-width: calc(100% - 60px);
  width: 100%;
  margin: 0 auto 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.intro__aboutLink {
  font: 1em/1.3em var(--font-family-pt-sans);
  margin: 0;
  padding: 0;
  color: rgba(50, 50, 50, 0.5);
  align-self: center;
  max-width: 400px;
  width: 70%;
}

@media screen and (max-width: 1023px) {
  .intro__card {
    min-height: 212px;
  }
  .intro__cardTitle {
    max-width: 306px;
    width: 100%;
    padding-top: 26px;
    line-height: 1.3em;
    letter-spacing: -0.7px;
  }
  .intro__cardSubtitle {
    max-width: 306px;
    width: 100%;
    min-height: 45px;
    margin: 10px auto 28px;
    font-size: 1em;
  }
  .intro__linkContainer {
    max-width: calc(100% - 56px);
  }
  .intro__aboutLink {
    max-width: 250px;
  }
}

@media screen and (max-width: 767px) {
  .intro__card {
    min-height: 150px;
  }
  .intro__cardTitle {
    max-width: calc(100% - 24px);
    width: 100%;
    margin: 11px auto 0;
    padding: 0;
    font-size: 1.86em;
    line-height: 1.23em;
    letter-spacing: 0;
  }
  .intro__cardSubtitle {
    max-width: calc(100% - 24px);
    width: 100%;
    min-height: 35px;
    margin: 10px auto 13px;
    font-size: 1em;
    line-height: 1.29em;
  }
  .intro__linkContainer {
    padding-bottom: 12px;
    max-width: calc(100% - 24px);
  }
  .intro__aboutLink {
    font-size: 0.86em;
    line-height: 1.3em;
  }
}
