ul {
  padding: 0;
  margin: 0;
}

.cardsSlider {
  display: flex;
  gap: 1.3889%;
  max-width: 1440px;
  max-height: 680px;
  margin: 0 auto;
}

.cardsSlider__bgSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 33.125%;
  max-width: 477px;
  min-width: 243px;
  background: var(--black-100);
  color: var(--color-text-white);
}

.cardsSlider__title {
  font: normal 400 46px/46px var(--font-family-wf);
  margin: 0;
}

.cardsSlider__bgTitle {
  margin: 20px 40px 0 32px;
  text-align: start;
}

.cardsSlider__sliderTitle {
  color: var(--black-100);
  text-align: start;
  line-height: 120%;
  margin: 16px 0 24px;
}

.cardsSlider__linkButtonContainer {
  display: flex;
  align-items: center;
  align-self: flex-end;
  gap: 1.3889vw;
  margin-inline-end: 31px;
  margin-block-end: 40px;
}

.cardsSlider__textLink {
  font: normal 400 20px/26px var(--font-family-pt-sans);
}

.cardsSlider__sliderContainer {
  width: 65.4861%;
}

.swiper {
  max-width: 943px;
}

.swiper__slide {
  margin-block-end: 65px;
  padding-top: 95px;
}

.swiper__navContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  z-index: 100;
  width: 100%;
}

.swiper__btnContainer {
  display: flex;
  gap: 12px;
  padding-inline-end: 32px;
  padding-block-end: 8px;
}

.swiper__scrollbar {
  left: 0 !important;
  bottom: 18px !important;
}
.swiper-horizontal > .swiper-scrollbar {
  height: 4px;
}

.sliderMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.8vw 3.3333vw 0;
  min-width: 336px;
  box-sizing: border-box;
  gap: 16px;
}
.title__container {
  width: 100%;
}

.sliderMobile__title {
  margin: 0;
  font: normal 400 32px/120% var(--font-family-wf);
  text-align: start;
}
.sliderMobile__linkButtonContainer {
  display: flex;
  align-items: center;
  min-height: unset;
  height: 24px;
  padding-top: 1px;
  box-sizing: border-box;
}
.sliderMobile__linkButtonContainer a {
  border: none;
  justify-content: flex-start;
}
.sliderMobile__linkButtonContainer img {
  width: 21px;
  margin-left: 6px;
}
.sliderMobile__linkButtonContainer a:hover {
  border: none;
}

.swiperMobile {
  max-width: 352px;
  width: 72vw;
  overflow: hidden;
  height: auto;
  box-sizing: border-box;
  padding-inline-start: 12px;
}

@media screen and (max-width: 1023px) {
  .cardsSlider__sliderTitle {
    font-size: 30px;
    margin: 16px 0 24px;
  }
  .cardsSlider__bgTitle {
    font-size: 30px;
    line-height: 37px;
    margin: 16px 40px 0 16px;
  }
  .cardsSlider__linkButtonContainer {
    margin-inline-end: 20px;
    gap: 20px;
  }
  .swiper__slide {
    margin-block-end: 27px;
    padding-top: 70px;
  }
  .cardsSlider__textLink {
    font-size: 14px;
    line-height: 18.12px;
  }
  .swiper__scrollbar {
    bottom: 11px !important;
  }
  .swiper__btnContainer {
    padding-inline-end: 16px;
    padding-block-end: 3px;
  }
}

@media screen and (max-width: 767px) {
  .sliderMobile {
    max-width: 360px;
    margin: 0 auto;
  }
}
